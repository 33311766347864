import {OrderPreset} from "utils/types/primary/OrderPreset";

import {create} from 'zustand'

interface State {
    presetList: OrderPreset[]
    searchBar: string
    currentSelectedRows: OrderPreset[]
}

interface Actions {
    setPresetList: (presetList: OrderPreset[]) => void

    setSearchBar: (value: string) => void

    isAnySelected: () => boolean
    checkAllRows: (presets: OrderPreset[]) => void
    resetAllRows: () => void

    checkRow: (preset: OrderPreset) => void
    isRowChecked: (preset: OrderPreset) => boolean

    resetPresets: () => void
}

const usePresets = create<State & Actions>(
    (set, get) => ({
        presetList: [],

        searchBar: '',

        currentSelectedRows: [],

        resetPresets: () => {
            set(
                (state) => (
                    {
                        presetList: [],

                        searchBar: '',

                        currentSelectedRows: [],
                    }
                )
            )
        },

        resetAllRows: () => {
            set(
                (state) => (
                    {
                        currentSelectedRows: []
                    }
                )
            )
        },

        isAnySelected: () => {
            const currentSelected = usePresets.getState().currentSelectedRows;
            if (currentSelected.length > 0) {
                return true
            } else return false
        },

        isRowChecked: (preset: OrderPreset) => {
            const currentSelected = usePresets.getState().currentSelectedRows.filter(el => el.name === preset.name);
            if (currentSelected.length > 0) {
                return true
            } else return false
        },

        checkAllRows: (presets: OrderPreset[]) => {
            const currentSelected = usePresets.getState().currentSelectedRows;
            if (currentSelected.length > 0) {
                set(
                    (state) => (
                        {
                            currentSelectedRows: []
                        }
                    )
                )
            } else {
                set(
                    (state) => (
                        {
                            currentSelectedRows: [...state.currentSelectedRows, ...presets]
                        }
                    )
                )
            }
        },

        checkRow: (preset: OrderPreset) => {
            const currentSelected = usePresets.getState().currentSelectedRows.filter(el => el.name === preset.name);

            if (currentSelected.length > 0) {
                const updatedArr = usePresets.getState().currentSelectedRows.filter(el => el.name !== preset.name)
                set(
                    (state) => (
                        {
                            currentSelectedRows: updatedArr
                        }
                    )
                )
            } else {
                set(
                    (state) => (
                        {
                            currentSelectedRows: [...state.currentSelectedRows, preset]
                        }
                    )
                )
            }
        },

        setPresetList: (presetList: OrderPreset[]) => {
            set(
                (state) => (
                    {
                        presetList: presetList,
                    }
                )
            );
        },

        setSearchBar: (value: string) => {
            set(
                (state) => (
                    {
                        searchBar: value
                    }
                )
            )
        },
    })
)

export default usePresets;