import {FilterItem} from "utils/types/suibsidiary/filterItem";

const orderStatusFilter: FilterItem[] = [
    {
        checked: false,
        filterItemKey: "\"status\":",
        filterItemValue: "[DRAFT]",
        filterItemTitle: "Draft",
    },
    {
        checked: false,
        filterItemKey: "\"status\":",
        filterItemValue: "[QUOTE]",
        filterItemTitle: "Quote",
    },

    {
        checked: true,
        filterItemKey: "\"status\":",
        filterItemValue: "[NEW]",
        filterItemTitle: "New",
    },


    {
        checked: true,
        filterItemKey: "\"status\":",
        filterItemValue: "[APPROVED]",
        filterItemTitle: "Approved",
    },

    {
        checked: false,
        filterItemKey: "\"status\":",
        filterItemValue: "[IN_PROGRESS]",
        filterItemTitle: "In progress",
    },

    {
        checked: false,
        filterItemKey: "\"status\":",
        filterItemValue: "[COMPLETED]",
        filterItemTitle: "Completed",
    },

    {
        checked: false,
        filterItemKey: "\"status\":",
        filterItemValue: "[CANCELED]",
        filterItemTitle: "Cancelled",
    },

    {
        checked: false,
        filterItemKey: "\"status\":",
        filterItemValue: "[CLOSED]",
        filterItemTitle: "Closed",
    },
]

export default orderStatusFilter