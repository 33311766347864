import {toast} from "cn/components/ui/use-toast";
import {useEffect, useState} from "react";
import useOnclickOutside from "react-cool-onclickoutside";
import approveOrder from "utils/API/approveOrder";
import {OrderStatus} from "utils/enum/orderStatus";
import {ErrorMessage} from "utils/types/primary/errorMessage";
import {Order} from "utils/types/primary/Order";
import useProgressBar from "utils/zustand/primary/useProgressBar";

interface Props {
    orders: Order[]
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
    setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>
    mutateOrderSource: () => void
    resetOrders: () => void
}

export default function OrderActionsContent({orders, setOpen, setDialogOpen, resetOrders, mutateOrderSource}: Props) {
    const [isAnyOpenStatus, setAnyOpenStatus] = useState<boolean>(false);
    const {setProgressValue, resetProgressBar} = useProgressBar()

    const isOutside = useOnclickOutside(() => {
        setOpen(false)
    });

    async function onApproveHandle() {
        setOpen(false);

        const openOrders = orders.filter(el => el.status === OrderStatus.new);
        const errorMessage: ErrorMessage[] = []
        const dataLoadPace = Number((100 / openOrders.length).toFixed(0));

        for (let i = 0; i < openOrders.length;) {
            const res = await approveOrder(openOrders[i]);

            if (res !== 200) {
                errorMessage.push(res)
            }

            setProgressValue(useProgressBar.getState().progressValue + dataLoadPace)
            i++
        }

        if (errorMessage.length > 0) {
            toast({
                variant: 'destructive',
                description: <section className={'flex flex-col gap-2'}>{errorMessage.map(el => <div
                    className={'flex flex-col text-xs font-inter'}>
                    <p>Error {el.status}:</p>
                    <p>{el.message}</p>
                </div>)}
                </section>
            })
        } else {
            toast({
                description: 'Request fetched'
            })
        }

        resetProgressBar();
        resetOrders();
        await mutateOrderSource();
    }

    useEffect(() => {
        const openOrders = orders.filter(el => el.status === OrderStatus.new);
        if (openOrders.length > 0) {
            setAnyOpenStatus(true)
        } else setAnyOpenStatus(false)
    }, [orders]);

    return <ul ref={isOutside} className={'w-full h-full px-2 py-2 font-medium text-sm'}>
        {
            isAnyOpenStatus &&
            <li
                key={'approve'}
                onClick={onApproveHandle}
                className={'py-[0.15rem] px-2 cursor-pointer itemHover'}>
                Approve
            </li>
        }

        <li
            key={'delete'}
            className={'py-[0.15rem] px-2 cursor-pointer itemHover text-marcoSecondaryErrorColor'}
            onClick={() => {
                setOpen(false)
                setDialogOpen(true)
            }}
        >
            Delete selected
        </li>
    </ul>


}