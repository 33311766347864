import * as z from "zod";

const companySchema = z.object({
    id: z.string(),

    members: z.array(z.object({
        id: z.string(),
        companyID: z.string(),
        firstName: z.string(),
        lastName: z.string(),
        email: z.string().email(),
        phone: z.string(),
        createOn: z.string(),
        lastUpdate: z.string(),
    })),

    stripeAccount: z.string(),
    firstName: z.string(),
    lastName: z.string(),
    email: z.string(),
    phone: z.string(),
    legalName: z.string(),
    companyName: z.string(),
    address1: z.string(),
    address2: z.string(),
    city: z.string(),
    state: z.string(),
    country: z.string(),
    addressPhone: z.string(),
    addressFirstName: z.string(),
    addressLastName: z.string(),
    createOn: z.string(),
    lastUpdate: z.string(),
    dot: z.string(),
    fmsc: z.string(),
    ein: z.string(),
})

export default companySchema